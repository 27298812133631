.Icon{
	font-size: 8.5rem;
	width: 3.5em;
	color: #2b9348;
}

@media (min-width: 360px){
	.Icon{
		font-size: 8.5rem !important;
		width: 1em !important;
		color: #2b9348;
	}
}
